import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import App from './AppM';
import reportWebVitals from './reportWebVitals';
import AppAll from './AppAll';
window.bkl = {
    mail: "bacara331@163.com",
    addr: "河南省郑州市郑东新区商鼎路78号3号楼2单元909室",
    phone: "19949195095"
}
ReactDOM.render(
    <React.StrictMode>
        <AppAll />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Doc from "./doc.js";


const PCApp = lazy(() => import("./App.js"))
const MobileApp = lazy(() => import("./AppM.js"))

const os = function () {
	let ua = navigator.userAgent,
		isWindowsPhone = /(?:Windows Phone)/.test(ua),
		isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
		isAndroid = /(?:Android)/.test(ua),
		isFireFox = /(?:Firefox)/.test(ua),
		isChrome = /(?:Chrome|CriOS)/.test(ua),
		isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua)),
		isPhone = /(?:iPhone)/.test(ua) && !isTablet,
		isPc = !isPhone && !isAndroid && !isSymbian;
	return {
		isTablet: isTablet,
		isPhone: isPhone,
		isAndroid: isAndroid,
		isPc: isPc
	};
}();



export default class AppAll extends React.Component {
	doc = new Doc()
	constructor(props) {
		super(props)
	}
	async componentDidMount() {
		let docs = await this.doc.getDocsByProjectTitle('栏目banner');
		let docsDetail = await this.doc.getDocsByDocIdList(docs.map(item => item.id));
		docsDetail.forEach(doc => {
			localStorage.setItem(`subBaner${doc.name}`, doc.md_content)
		})
	}
	render() {
		return <Suspense fallback={<div>loading</div>}>
			<BrowserRouter>
				<Routes>
					<Route exact path='/*' element={<PCApp />}></Route>
					<Route path='/pc/*' element={<PCApp />}></Route>
					<Route path='/mobile/*' element={<MobileApp />}></Route>
				</Routes>
			</BrowserRouter>
		</Suspense>

	}
}
import axios from 'axios'
import Markdown from 'react-markdown'
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom'
import './index.css'
import moment from 'moment'
const token = 'af200273ef8ec7785b3b1ead8a96f9290f3aeeaca4ffa9191ccf9932'
const url = 'http://doc.bacaraenergy.com'
export default class Doc {

    getDoc(doc) {
        return new Promise((res, rej) => {
            axios.get(url + "/api/get_doc/?token=" + token + "&did=" + doc.id)
                .then(res2 => {
                    res(res2.data.data)
                })
                .catch(rej)
        })
    }
    async getDocsByDocIdList(docIdList) {
        let docArr = []
        for (var docId of docIdList) {
            let res2 = await axios.get(url + "/api/get_doc/?token=" + token + "&did=" + docId)
            //docArr[docId] = res2.data.data
            docArr.push(res2.data.data)
        }
        docArr.sort((p, q) => {
            let diff = moment(p.create_time).diff(moment(q.create_time))
            return -diff;
        });
        return docArr;
        // return new Promise(async (res, rej) => {
        //     let total = 0;
        //     let tryTime = 0;
        //     let docArr = []
        //     docIdList.forEach(async docId => {
        //         let res2 = await axios.get(url + "/api/get_doc/?token=" + token + "&did=" + docId)
        //         //docArr[docId] = res2.data.data
        //         docArr.push(res2.data.data)
        //     })
        //     res(docArr);

        // });
    }

    getDocsTitleByProjectId(projid) {
        return new Promise((res, rej) => {
            axios.get(url + "/api/get_docs/?token=" + token + "&pid=" + projid)
                .then(res2 => {
                    let sorted = res2.data.data.filter(q => q.status == 1).sort((p, q) => {
                        let diff = moment(p.create_time).diff(moment(q.create_time))
                        return diff > 0 ? 1 : -1;
                    });
                    res(sorted);
                })
                .catch(err => {
                    rej(err);
                })
        });
    }
    async getDocsByProjectTitle(title) {
        let that = this
        return new Promise((res, rej) => {
            that.getProjects().then(projs => {
                let proj = projs.filter(q => q.name == title)[0];
                that.getDocsTitleByProjectId(proj.id).then(res).catch(rej)
            })
        })
    }
    getProjects() {
        return new Promise((res, rej) => {
            axios.get(url + "/api/get_projects/?token=" + token)
                .then(res1 => {
                    res(res1.data.data)
                })
                .catch(err => {
                    rej(err);
                });
        })
    }
}
